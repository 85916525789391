import { DestroyRef, Injectable, input } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { Router } from '@angular/router';
import {
	BaseContentService,
	Content,
	IContent,
	IRecord,
	IRecordFilter,
	IReportConfig,
	ReportEventType
} from '@soleran/contracts';
import { ContentEvent } from '@soleran/ngx-content';
import { ExportDialogService } from '@soleran/ngx-import-export';
import { FormMode, IPageBuilderState, selectModule, selectRecord } from '@soleran/ngx-layout';
import { ModuleService } from '@soleran/ngx-module';
import { RecordService } from '@soleran/ngx-record';
import { ReportConfigService } from '@soleran/ngx-report';
import { combineLatest, map, mergeMap, Observable, of, shareReplay, take, tap } from 'rxjs';
import { GlobalDrawerService } from '../../global-drawer/global-drawer.service';
import { RecordModalComponent } from '../modals/record/record-modal.component';
import { Store } from '@ngrx/store';

const REPORT_DIRECTORY_URL = 'https://localhost:4200/report/directory';

@Injectable({
	providedIn: 'root'
})
export class ReportContentService extends BaseContentService {
	reportConfigCache: Record<string, { reportConfig: any, timestamp: number }> = {};

	constructor(
		private _router: Router,
		private _reportConfigService: ReportConfigService,
		private _moduleService: ModuleService,
		private _recordService: RecordService,
		private _store: Store<IPageBuilderState>,
		private _drawerService: GlobalDrawerService,
		private _dialog: ExportDialogService,
		private _destroyRef: DestroyRef
	) {
		super();
	}

	override canHandle(type: Content): boolean {
		return type === Content.Report;
	}

	override getInputs(contentId: any): Observable<any> {
		const record$ = this._store.select(selectRecord);
		const module$ = this._store.select(selectModule);
		const reportConfig$ = this._getReportConfig(contentId);
		const filteredReportConfig$ = combineLatest([reportConfig$, module$, record$]).pipe(
			take(1),
			map(([reportConfig, module, record]) => {
				if (!module || !record) return reportConfig;
				const filteredReportConfig = { ...reportConfig };
				const recordFilter: IRecordFilter = {
					moduleId: module.id,
					recordId: record.id
				};
				filteredReportConfig.recordFilter = recordFilter;
				return filteredReportConfig;
			})
		);
		const inputs$ = filteredReportConfig$.pipe(shareReplay(1)).pipe(map((reportConfig) => ({ reportConfig })));
		return inputs$;
	}
	private _getReportConfig(contentId) {
		const currentTime = Date.now();

		if (this.reportConfigCache[contentId] && (currentTime - this.reportConfigCache[contentId].timestamp) < 60000) {
			return of(this.reportConfigCache[contentId].reportConfig);
		}
		const reportConfig$ = this._reportConfigService.get(contentId).pipe(
			tap(reportConfig => {
				this.reportConfigCache[contentId] = { reportConfig, timestamp: currentTime };
			})
		);
		return reportConfig$;
	}

	override get = (config: any) => {
		return this._reportConfigService.get().pipe(
		  map(reportConfigs => {
			if (!config?.moduleId) return reportConfigs;
			return reportConfigs.filter(reportConfig => {
			  const primaryModuleId = [reportConfig.moduleId];
			  const joinedModuleIds = reportConfig.joinConfigs?.map(joinConfig => joinConfig.rightModuleId) ?? [];
			  const reportModuleIds = joinedModuleIds.concat(primaryModuleId);
			  return reportModuleIds.some(moduleId => moduleId === config.moduleId);
			})
		  }),
		  map((reportConfigs) => {
			return reportConfigs.map((reportConfig) => {
			  const content: IContent = {
				id: reportConfig.id,
				type: Content.Report,
				display: reportConfig.name,
			  };
			  return content;
			});
		  })
		);
	  };

	override getOne = (id: string) => {
		return this._reportConfigService.get(id).pipe(
			map((reportConfig) => {
				if (!reportConfig) {
					throw new Error(`Content - Report '${id}' Not Found`);
				}
				const content: IContent = {
					id: reportConfig.id,
					type: Content.Report,
					display: reportConfig.name
				};
				return content;
			})
		);
	};
	override create = (config: any) => {
		const url = `${REPORT_DIRECTORY_URL}/new`;
		window.open(url, '_blank');
		return of(undefined);
	};

	override update = (config: any) => {
		const url = `${REPORT_DIRECTORY_URL}/${config}/edit`;
		window.open(url, '_blank');
		return of(undefined);
	};

	override handleEvent = (config: ContentEvent) => {
		switch (config.contentEventType) {
			case ReportEventType.AddRecord:
				return this._handleAddRecordEvent(config);
			case ReportEventType.EditRecord:
				return this._handleEditRecordEvent(config);
			case ReportEventType.Analyze:
				return this._handleAnalyzeReportEvent(config);
			case ReportEventType.Import:
				return this._handleImportEvent(config);
			case ReportEventType.Export:
				return this._handleExportEvent(config);
			case ReportEventType.RecordLink:
				return this._handleRecordLinkEvent(config);
			case ReportEventType.EditReport:
				return this._handleEditReportEvent(config);
			default:
				return of();
		}
	};

	private _handleAddRecordEvent(config: ContentEvent) {
		const event = config.event;
		return this._moduleService.get(event.moduleId).pipe(
			takeUntilDestroyed(this._destroyRef),
			mergeMap((module) => this._drawerService.open(RecordModalComponent, { module })),
			map((record) => ({ record, contentEventType: config.contentEventType }))
		);
	}
	private _handleEditRecordEvent(config: ContentEvent) {
		const event = config.event;
		const module$ = this._moduleService.get(event.moduleId);
		const record$ = this._recordService
			.get(event.recordId, event.moduleId)
			.pipe(map((response: any) => response as IRecord));
		return combineLatest([module$, record$]).pipe(
			takeUntilDestroyed(this._destroyRef),
			mergeMap(([module, record]) =>
				this._drawerService.open(RecordModalComponent, { module, record, mode: FormMode.Edit })
			),
			map((record) => ({ record, contentEventType: config.contentEventType }))
		);
	}
	private _handleImportEvent(config: ContentEvent) {
		const event = config.event;
		const moduleId = event.moduleId;
		this._router.navigate(['object', moduleId, 'import']);
		return of();
	}
	private _handleAnalyzeReportEvent(config: ContentEvent) {
		const event = config.event;
		this._router.navigate(['/', 'report', 'metrics', event.id]);
		return of();
	}

	private _handleExportEvent(config: ContentEvent) {
		const reportConfig = config.event;
		return this._openExportDialog(reportConfig)
			.afterClosed()
			.pipe(takeUntilDestroyed(this._destroyRef));
	}
	private _handleRecordLinkEvent(config: ContentEvent) {
		const event = config.event;
		this._router.navigate(['/', 'object', event.fieldConfig.moduleId, 'record', event.recordId]);
		return of();
	}
	private _handleEditReportEvent(config: ContentEvent) {
		const event = config.event;
		this._router.navigate(['/', 'report', 'directory', event.id, 'edit']);
		return of();
	}
	private _openExportDialog(reportConfig: IReportConfig) {
		this._throwIfNoReportConfigId(reportConfig);
		const ref = this._dialog.open({ reportConfigId: reportConfig.id });
		return ref;
	}
	private _throwIfNoReportConfigId(reportConfig: IReportConfig) {
		if (!reportConfig?.id) throw Error('Report config id required for export');
	}
}
