import { Component, DestroyRef, Input } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { IFormChange, IModule, IRecord } from '@soleran/contracts';
import { FormMode } from '@soleran/ngx-layout';
import { RecordService } from '@soleran/ngx-record';
import { GlobalDrawerService } from '../../../global-drawer/global-drawer.service';

@Component({
	selector: 'app-record-modal',
	templateUrl: './record-modal.component.html',
	styleUrl: './record-modal.component.scss'
})
export class RecordModalComponent {
	@Input({ required: true }) module!: IModule;
	@Input() record?: IRecord;
	@Input() mode = FormMode.Edit;

	constructor(
		private _globalDrawer: GlobalDrawerService,
		private _recordService: RecordService,
		private _destroyRef: DestroyRef
	) {}

	formState: IFormChange<IRecord> = { valid: false, value: { id: 0 } };
	EDIT = FormMode.Edit;

	onFormChange(change: IFormChange<IRecord>) {
		this.formState = change;
	}
	getKeyFieldDisplay(module: IModule, record: IRecord) {
		const keyField = module.keyField;
		return record[keyField];
	}
	cancel() {
		this._globalDrawer.close();
	}
	submit() {
		if (this.record) {
			this._recordService
				.update(this.record.id, this.module.id, this.formState.value)
				.pipe(takeUntilDestroyed(this._destroyRef))
				.subscribe((record) => this._globalDrawer.close(this._globalDrawer.close(record)));
		} else {
			this._recordService
				.create(this.module.id, this.formState.value)
				.pipe(takeUntilDestroyed(this._destroyRef))
				.subscribe((record) => this._globalDrawer.close(this._globalDrawer.close(record)));
		}
	}
}
