<mat-toolbar>
    {{ record ? getKeyFieldDisplay(module, record) : 'Add ' + module.name }}
</mat-toolbar>
	<app-record-form class="record-form" [module]="module" [record]="record" [formMode]="mode" (formChange)="onFormChange($event)" />
<mat-toolbar class="bottom-bar">
    <ng-container *ngIf="mode === EDIT; else readonly;">
        <button mat-button (click)="cancel()">Cancel</button>
        <button mat-button color="primary" [disabled]="!formState.valid" (click)="submit()">Save</button>
    </ng-container>
</mat-toolbar>
<ng-template #readonly>
    <button mat-button (click)="cancel()">Done</button>
</ng-template>

